import type { GatsbyBrowser } from "gatsby"
// eslint-disable-next-line @typescript-eslint/no-unused-vars, import/default
import React from "react"
import RootWrapper from "./src/components/RootWrapper"
import "./src/styles/global/index.scss"

/*************************************************
 Wraps all pages of our app in our DonutStateProviderComponent (declared below)
 which contains our UserContext provider.
 https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapPageElement
 **************************************************/
export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return <RootWrapper>{element}</RootWrapper>
}
