import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { Provider } from "react-redux"
import { store } from "redux/store"

const RootWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Provider store={store}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY as string}
      >
        {/* <PersistGate loading={null} persistor={persistor}> */}
        {children}
        {/* </PersistGate> */}
      </GoogleReCaptchaProvider>
    </Provider>
  )
}

export default RootWrapper
